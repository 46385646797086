@import '../../styles/customMediaQueries.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
  }
}

.carrierHeading {
  font-size: 14px;
  color: var(--matterColorDark);
  margin-bottom: 16px;
  font-weight: 600;
}
.shippingDetailsContainer {
  & .loader {
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
}
.carriers {
  display: flex;
  flex-wrap: wrap;
  & .carriersContainer {
    margin-bottom: 20px;
    border-radius: 10px;
    width: 100%;
    @media (--viewportSmall) {
      margin-right: 20px;
      width: calc(100% / 2 - 10px);
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    & .cardCarrier {
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 10px 20px;
      justify-content: space-between;
      cursor: pointer;
      border: solid 1px var(--matterColorNegative);
      &.selectedCard {
        border: 1px solid var(--marketplaceColorDark);
        background: var(--matterColorBright);
      }

      & .cardBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .providerImage {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            max-width: 100%;
            object-fit: contain;
          }
        }
        & .cardCarrierData {
          flex-direction: column;
          display: flex;
          width: calc(100% - 60px);
          padding-left: 10px;
          & .title {
            margin: 0;
          }
          & .providerName {
            font-weight: 700;
            font-size: 15px;
            line-height: 120%;
          }
        }
      }
    }
  }
}
